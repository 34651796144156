import olympusFrontend from './assets/olympusdao-frontend.png'
import componentLibrary from './assets/component-library.png'
import tender from './assets/tender.png'
import wallet from './assets/wallet.png'
import range from './assets/range.png'
import pr0xy from './assets/pr0xy.png'

import discord from './assets/discord.png'
import zapper from './assets/zapper.png'
import ethereum from './assets/ethereumorg-bg.png'

const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'http://brightiron.xyz',
  title: 'Brightiron_',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Brightiron',
  role: 'Senior Full-Stack Web3 Engineer',
  description: `With over a decade of meatspace engineering experience, I've built products used by millions, some of which you probably use every day. In 2021, I decided to go all in on building the future of finance. I'm excited about helping to build this future and I'd love to learn more about what you're building. Please reach out.`,
  social: {
    twitter: 'https://twitter.com/Brightiron_',
    github: 'https://github.com/brightiron',
    email: `mailto:brightiron@protonmail.com`,
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'OlympusDAO Frontend',
    description: `Lead Frontend Dev for Core `,
    stack: ['TypeScript', 'MUI', 'React', 'Ethers.js'],
    sourceCode: 'https://github.com/olympusdao/olympusdao-frontend',
    screenshot: olympusFrontend,
    livePreview: 'https://app.olympusdao.finance',
  },
  {
    name: 'OlympusDAO Range Stablity Frontend',
    description: `Frontend for Olympus' new Range Stability Model Framework. Interacting with Bond Markets and Range Operator Contracts`,
    stack: ['Typescript', 'MUI', 'React', 'Ethers.js'],
    sourceCode: 'https://github.com/olympusdao/olympusdao-frontend',
    screenshot: range,
    livePreview: 'https://app.olympusdao.finance',
  },
  {
    name: 'PR0XYDAO',
    description: `A demo governance system built on the Default Smart Contract framework.`,
    stack: ['Typescript', 'NextUI', 'React', 'Ethers.js'],
    screenshot: pr0xy,
  },
  {
    name: 'OlympusDAO Component Library',
    description:
      'Built/released a npm package with reusable UI Component Architecture allowing teams to iterate quickly on new UIs, all while implementing consistent design patterns',
    stack: ['TypeScript', 'MUI', 'React', 'Storybook'],
    screenshot: componentLibrary,
    sourceCode: 'https://www.npmjs.com/package/@olympusdao/component-library',
    livePreview: 'https://master--61c4d644c064da004aebdd97.chromatic.com/',
  },
  {
    name: 'Ethereum.org',
    description: 'Casual contributor to the Ethereum.org frontend.',
    stack: ['TypeScript', 'React'],
    sourceCode: 'https://github.com/ethereum/ethereum-org-website',
    screenshot: ethereum,
    livePreview: 'https://www.ethereum.org',
  },
  {
    name: 'OlympusDAO - Tender Offer Frontend',
    description:
      'Designed and built a system intended to be used for extending tender offers for acquiring other protocols. Handled escrow and redemption. ',
    stack: ['Typescript', 'MUI', 'React', 'Ethers.js'],
    sourceCode: 'https://github.com/olympusdao/olympusdao-frontend',
    screenshot: tender,
    livePreview: 'https://app.olympusdao.finance',
  },
  {
    name: 'OlympusDAO Wallet Manager',
    description:
      'Built the Wallet Manager. Tracks Balances, transaction history, calculates APY and more',
    stack: ['Typescript', 'MUI', 'React', 'Ethers.js'],
    sourceCode: 'https://github.com/olympusdao/olympusdao-frontend',
    screenshot: wallet,
    livePreview: 'https://app.olympusdao.finance',
  },

  {
    name: 'Zapper Studio Integration',
    description:
      'Built a Zapper Studio integration to display Olympus Bond Positions',
    stack: ['Typescript', 'Ethers.js'],
    sourceCode: 'https://github.com/zapper-fi/studio/',
    livePreview: 'https://zapper.fi/dashboard',
    screenshot: zapper,
  },
  {
    name: 'Discord Invite Spam Protection',
    description:
      'Designed to prevent spam bots from joining your discord server. This Next.js app provides both a reCAPTCHA (V2) and one-time use discord invite upon successful completion of the reCAPTCHA.',
    stack: ['Next.js', 'TypeScript', 'React', 'MUI', 'Discord API'],
    sourceCode: 'https://github.com/brightiron/discord-invite-recaptcha',
    screenshot: discord,
    livePreview: 'https://github.com/brightiron/discord-invite-recaptcha',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Typescript',
  'Javascript',
  'Next.js',
  'Node.js',
  'React',
  'React Query',
  'Ethers.js',
  'TypeChain',
  'WAGMI',
  'Redux',
  'MUI',
  'Jest',
  'Ruby on Rails',
  'SASS',
  'CSS',
  'Git',
  'CI/CD',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'brightiron@protonmail.com',
}

export { header, about, projects, skills, contact }
